import { library } from "@fortawesome/fontawesome-svg-core"

/**
 * Brand icons.
 */
import { faMarkdown } from "@fortawesome/free-brands-svg-icons"

/**
 * Icons used in this application.
 */
import {
  faAnalytics,
  faArrowsV,
  faBookOpen,
  faBrowser,
  faBullseye,
  faCalendarTimes,
  faClone,
  faCode,
  faCopy,
  faCrown,
  faDesktop,
  faDownload,
  faEnvelope,
  faExternalLink,
  faEye,
  faFileAlt,
  faFileLines,
  faFilePdf,
  faFilm,
  faGem,
  faHashtag,
  faHeading,
  faImage,
  faImageLandscape,
  faLayerGroup,
  faLink,
  faList,
  faLock,
  faLongArrowLeft,
  faLongArrowRight,
  faMapMarker,
  faMegaphone,
  faStickyNote,
  faPalette,
  faPencil,
  faPhoneAlt,
  faPhotoVideo,
  faPlayCircle,
  faPlus,
  faQuestion,
  faQrcode,
  faServer,
  faShare,
  faShoppingBag,
  faSquareCheck,
  faTachometerAverage,
  faTachometerFast,
  faTachometerSlow,
  faTag,
  faText,
  faThLarge,
  faThumbtack,
  faTrash,
  faUndo,
  faUserCircle,
  faUserLock,
  faUsers,
  faWaveform
} from "@fortawesome/pro-regular-svg-icons"

import {
  faAnalytics as faAnalyticsSolid,
  faBars as faBarsSolid,
  faBullseye as faBullseyeSolid,
  faEllipsisH as faEllipsisHSolid,
  faFolder as faFolderSolid,
  faLanguage as faLanguageSolid,
  faPhotoVideo as faPhotoVideoSolid,
  faSearch as faSearchSolid,
  faSquareCheck as faSquareCheckSolid,
  faTrash as faTrashSolid
} from "@fortawesome/pro-solid-svg-icons"

import { faSpinnerThird as faSpinnerThirdDuotone } from "@fortawesome/pro-duotone-svg-icons"

library.add(
  /**
   * Brand icons.
   */
  faMarkdown,

  /**
   * Regular Weight Icons.
   */
  faAnalytics,
  faArrowsV,
  faBookOpen,
  faBrowser,
  faBullseye,
  faCalendarTimes,
  faClone,
  faCode,
  faCopy,
  faCrown,
  faDesktop,
  faDownload,
  faEnvelope,
  faExternalLink,
  faEye,
  faFileAlt,
  faFileLines,
  faFilePdf,
  faFilm,
  faGem,
  faHashtag,
  faHeading,
  faImage,
  faImageLandscape,
  faLayerGroup,
  faLink,
  faList,
  faLock,
  faLongArrowLeft,
  faLongArrowRight,
  faMapMarker,
  faMegaphone,
  faStickyNote,
  faPalette,
  faPencil,
  faPhoneAlt,
  faPhotoVideo,
  faPlayCircle,
  faPlus,
  faQuestion,
  faQrcode,
  faServer,
  faShare,
  faShoppingBag,
  faSquareCheck,
  faTachometerAverage,
  faTachometerFast,
  faTachometerSlow,
  faTag,
  faText,
  faThLarge,
  faThumbtack,
  faTrash,
  faUndo,
  faUserCircle,
  faUserLock,
  faUsers,
  faWaveform,

  /**
   * Solid Weight Icons.
   */
  faAnalyticsSolid,
  faBarsSolid,
  faBullseyeSolid,
  faEllipsisHSolid,
  faFolderSolid,
  faLanguageSolid,
  faPhotoVideoSolid,
  faSearchSolid,
  faSquareCheckSolid,
  faTrashSolid,

  /**
   * Duotone Icons.
   */
  faSpinnerThirdDuotone
)
